import { Input } from '@material-tailwind/react';
import ErrorHelper from 'helpers/ErrorHelper';
import { customSelectStyles, defaultToastOptions } from 'options';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import { useStoreActions } from 'easy-peasy';
import Modal from 'components/modals/Modal';
import { Button } from '@nextui-org/react';
const twConfig = resolveConfig(tailwindConfig);

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditWebsite = ({ modelId = null }) => {
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [marketOptions, setMarketOptions] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const handleChange = (selectedMarket) => {
    setSelectedMarket(selectedMarket);
  };

  const handleSelectedUnitsChange = (selectedUnits) => {
    setSelectedUnits(selectedUnits);
  };

  const {
    data: units,
    isFetching,
    isError,
  } = useQuery(
    [
      'units',
      {
        fields: ['id', 'name'],
      },
    ],
    async () => {
      return await ApiService.get('/units', {
        params: {
          'fields[]': 'id,name',
          all: true,
        },
      });
    },
    {
      onSuccess: ({ data }) => {
        setUnitOptions(
          data.data.map((unit) => {
            return { value: unit.id, label: unit.name };
          }),
        );
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const { data: markets, isFetching: countriesIsFetching } = useQuery(
    [
      'countries',
      {
        fields: ['id', 'countryname'],
        per_page: 300,
      },
    ],
    async () => {
      const res = await ApiService.get('/countries', {
        params: {
          'fields[]': 'id,countryname',
          all: true,
          per_page: 300,
        },
      });
      return res.data;
    },
    {
      onSuccess: (data) => {
        setMarketOptions(
          data.data.map((market) => {
            return { value: market.id, label: market.countryname };
          }),
        );
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    data: formData,
    isFetching: formDataIsFetching,
    isError: formDataIsError,
  } = useQuery(
    ['website-form', modelId],
    async () => {
      return await ApiService.get(`websites/${modelId}/edit`);
    },
    {
      // enabled if modelId is given and markets are loaded
      enabled: !!modelId && !!marketOptions && !!unitOptions,
      onSuccess: ({ data }) => {
        setValue('name', data.data.name);
        setValue('url', data.data.url);
        setSelectedMarket({
          value: data.data.default_country_id,
          // get the label from the markets
          label: marketOptions?.find(
            (market) => market.value === data.data.default_country_id,
          )?.label,
        });
        // set units
        setSelectedUnits(
          // data.data.units contains the unit ids as an array, I need to find the unit in the unitOptions so I can set the selectedUnits
          data.data.units.map((unit) => {
            return {
              value: unit,
              label: unitOptions?.find(
                (unitOption) => unitOption.value === unit,
              )?.label,
            };
          }),
        );
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const createWebsiteMutation = useMutation(
    async (data) => await ApiService.post('/websites', data),
  );

  const updateWebsiteMutation = useMutation(
    async (data) => await ApiService.put('/websites/' + modelId, data),
  );

  const onSubmit = () => {
    if (!modelId) {
      if (!isDirty) {
        return;
      }
    }
    if (Object.keys(errors).length > 0 || selectedMarket.length === 0) {
      return;
    }
    const data = {
      name: getValues('name'),
      url: getValues('url'),
      country_id: selectedMarket.value,
      unit_ids: selectedUnits.map((unit) => unit.value),
    };
    const toastId = toast.loading(
      `${modelId ? 'Updating' : 'Creating'} website...`,
    );
    if (modelId) {
      return updateWebsiteMutation.mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries('websites');
          resetForm();
          closeModal();
          toast.update(toastId, {
            render: 'Website updated successfully',
            type: 'success',
            ...defaultToastOptions,
          });
        },
        onError: (error) => {
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          ErrorHelper.handleApiError(error.response.status, toastId);
        },
      });
    }
    createWebsiteMutation.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries('websites');
        resetForm();
        closeModal();
        toast.update(toastId, {
          render: 'Website created successfully',
          type: 'success',
          ...defaultToastOptions,
        });
      },
      onError: (error) => {
        if (error.response && error.response.status === 422) {
          return ErrorHelper.handleValidationErrors(
            error.response.data.errors,
            toastId,
          );
        }
        ErrorHelper.handleApiError(error.response.status, toastId);
      },
    });
  };

  const resetForm = () => {
    reset();
    setSelectedMarket([]);
    setSelectedUnits([]);
  };

  const validateURL = (value) => {
    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!value.match(urlRegex)) {
      return 'Invalid URL';
    }
    return true;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[20px] flex flex-col gap-y-6'>
          <div>
            <Input
              className=''
              variant='static'
              placeholder='Enter name'
              label='Name*'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('name', {
                required: true,
                minLength: 3,
                maxLength: 50,
              })}
            />
            {errors.name && errors.name.type === 'required' && (
              <HelperText text='Name is required' />
            )}
            {errors.name && errors.name.type === 'minLength' && (
              <HelperText text='Name must be at least 3 characters' />
            )}
          </div>
          <div>
            <Input
              className=''
              variant='static'
              label='Url*'
              placeholder='Enter URL'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('url', {
                required: true,
                minLength: 3,
                maxLength: 50,
                validate: validateURL,
              })}
            />
            {errors.url && errors.url.type === 'required' && (
              <HelperText text='Url is required' />
            )}
            {errors.url && errors.url.type === 'minLength' && (
              <HelperText text='Url must be at least 3 characters' />
            )}
            {errors.url && errors.url.type === 'validate' && (
              <HelperText text={errors.url.message} />
            )}
          </div>
          <div className='flex flex-col gap-y-1'>
            <span className='text-xs text-gray-700'>Default market*</span>
            <Select
              placeholder='Select default market'
              value={selectedMarket}
              onChange={handleChange}
              options={marketOptions}
              styles={customSelectStyles}
            />
          </div>
          <div className='flex flex-col gap-y-1'>
            <span className='text-xs text-gray-700'>Units</span>
            <Select
              placeholder='Select units'
              value={selectedUnits}
              onChange={handleSelectedUnitsChange}
              options={unitOptions}
              isMulti
              styles={customSelectStyles}
            />
          </div>
        </div>
        <div className='flex justify-end gap-2 pb-4'>
          <Button
            onPress={() => {
              resetForm();
              closeModal();
            }}
            variant='light'
            size='lg'
            fullWidth
            className='text-foreground-500'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            color='primary'
            size='lg'
            fullWidth
          >
            {modelId ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </>
  );
};
export default EditWebsite;
